<universal-modal-base>
  <ng-container modalHeader>
    <h2>{{ 'kyc.modal.title' | translate }}</h2>
  </ng-container>

  <ng-container modalBody>
    <div>
      <div>{{ 'kyc.modal.approval' | translate }}</div>
      <a [href]="accountSettingsLink" target="_self">{{ 'kyc.modal.uploadLink' | translate }}</a>
      <div [innerHTML]="'kyc.modal.contact' | translate | unsafe : 'html'"></div>
    </div>
  </ng-container>
</universal-modal-base>
