import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'u-kyc-required-modal',
  templateUrl: 'kyc-required-modal.component.html',
  styleUrls: ['./kyc-required-modal.component.scss'],
})
export class KycRequiredModalComponent implements OnInit {
  accountSettingsLink: string;

  ngOnInit() {
    this.accountSettingsLink = `https://sso.${environment.DEPLOY_SITEDOMAIN}/account/settings/info`;
  }
}
